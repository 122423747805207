#homebannerslider .carousel-item {
  min-height: 100vh;
}

.sports_slider .carousel_nav button {
  z-index: 1;
  top: 50%;
}

#homebannerslider .carousel-indicators li {
  background-color: #ff3f3f;
}

.sports_feature_section {
  background: url("../public/assets/images/backgrounds/bg_32.jpg");
  background-position: top right;
  background-repeat: no-repeat;
  background-size: contain;
}

.sports_feature_carousel button.slick-arrow {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.sports_feature_split.item .item_content {
  background-color: #000000;
}

.sports_feature_carousel .slick-dots {
  display: flex !important;
}

.slick-next:before {
  content: "\f061" !important;
  font-family: "Font Awesome 5 Pro" !important;
  font-weight: 100 !important;
  font-size: 16px !important;
}

.slick-prev:before {
  content: "\f060" !important;
  font-family: "Font Awesome 5 Pro" !important;
  font-weight: 100 !important;
  font-size: 16px !important;
}

.slick-prev {
  z-index: 1;
}

.home_sports {
  /* color: #f2f2f2; */
  color: #3b3b3b;
}

section.sports_big_feature {
  background-color: rgb(31, 31, 39);
}

.sports_newsletter {
  background: url("../public/assets/images/backgrounds/bg_33.jpg");
}

.sports_feature_video {
  background: url("../public/assets/images/backgrounds/bg_34.jpg");
}

.blog_items_wrap {
  background-color: rgb(31, 31, 39);
}

.footer_section {
  background-color: rgb(34, 34, 34);
}

.backtotop {
  background-image: url(../public/assets/images/shape_01.png);
}

.sports_feature_section .big_title {
  color: rgba(0, 0, 0, 0.05);
}

section.sports_feature_section {
  background-image: url(../public/assets/images/backgrounds/bg_32.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 56.9275px;
}

.sec_ptb_140 {
  padding: 80px 0px;
}

#products-carousel-home1 ul.slick-dots {
  display: flex !important;
}
#products-carousel-home2 ul.slick-dots {
  display: flex !important;
}
#products-carousel-home3 ul.slick-dots {
  display: flex !important;
}

#root {
  position: relative;
}

.scrolltotopbtndiv {
  position: fixed;
  bottom: 15px;
  z-index: 99;
  background: #ff3f3f;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
}
.scrolltotopbtndiv i {
  color: white;
}

.scrolltotopbtndiv:hover {
  bottom: 20px;
}

ul.details_image_nav {
  overflow: auto;
}

ul.details_image_nav::-webkit-scrollbar {
  width: 12px; /* Width of the vertical scrollbar */
  height: 5px; /* Height of the horizontal scrollbar */
}

ul.details_image_nav::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the track */
  border-radius: 5px; /* Rounded corners for the track */
}

ul.details_image_nav::-webkit-scrollbar-thumb {
  background: #ff3f3f; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
}

ul.details_image_nav::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the thumb on hover */
  cursor: pointer;
}

.details_image_nav li a img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.product-active-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.details_image {
  height: 400px;
}

.details_image_nav li a.active {
  border: 1px solid #ff3f3f;
}

.carparts_small_blog .item_image img {
  height: 60px;
  width: 95px;
  object-fit: contain;
}

/* mag */

.magnify-image {
  position: fixed;
  top: 100px;
  left: 50%;
  z-index: 9;
  background: white;
  width: calc(100vw - 52%);
  height: calc(100vh - 150px);
  border: 1px dotted;
  background-repeat: no-repeat;
  background-size: 250%;
}

.lens {
  position: absolute;
  border: 2px solid rgba(0, 0, 0, 0.5);
  background-color: rgba(255, 255, 255, 0.5);
  background-repeat: no-repeat;
  pointer-events: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  background-size: 250%;
  /* background-image: url(../public/assets/images/shop/sports/img_03.png); */
}

#productimages .modal-dialog {
  max-width: 100vw;
}

#productimages .modal-content {
  background-color: white;
  border: none;
}

#productimages button.close {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 10;
}
#productimages .carousel-inner {
  height: 100vh;
  width: 100vw;
}

#productimages .carousel-item {
  height: 100%;
  width: 100%;
}

#productimages .carousel-item img {
  width: 100vw;
  height: 100vh;
  object-fit: contain;
}

.product-carousel-indicators {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  z-index: 10;
  height: 60px;
  padding: 4px;
  overflow-x: scroll;
  display: flex;
  align-items: center;
  justify-content: start;
}
/* .product-indicators-box {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: nowrap;
  height: 100%;
  overflow: auto;
} */
.indicator-item {
  margin-right: 7px;
  background-color: white;
  /* width: 50px; */
  height: 50px;
  min-width: 50px;
  max-width: 50px;
  /* flex: 0 0 auto; */
}

.indicator-item img {
  height: 50px;
  width: 50px;
  object-fit: contain;
  max-width: auto;
  border-radius: 3px;
}

.productpage-main .sports_breadcrumb_nav_wrap {
  box-shadow: none;
}

/* .productpage-main .sec_ptb_100 {
  padding: 60px 0;
} */

/* pagination */

.page-item:first-child.disabled .page-link {
  border-radius: 5px;
  margin: 2px;
  background-color: #a1a1a1;
  color: white;
}
.page-item:first-child .page-link {
  border-radius: 5px;
  margin: 2px;
  background-color: black;
  color: white;
}

.page-item:last-child.disabled .page-link {
  border-radius: 5px;
  margin: 2px;
  background-color: #a1a1a1;
  color: white;
}
.page-item:last-child .page-link {
  border-radius: 5px;
  margin: 2px;
  background-color: black;
  color: white;
}

.page-item .page-link {
  background-color: #ffd8d8;
  color: black;
  border: none;
  margin: 2px;
  border-radius: 5px;
}

.page-item.active .page-link {
  background-color: #ff3f3f;
  color: white;
  border: none;
  margin: 2px;
  border-radius: 5px;
}

.page-item .page-link:focus {
  outline: none;
  box-shadow: none;
}

.reg_form_wrap form {
  background-color: white;
  padding: 20px 40px;
  border-radius: 20px;
}
.sports_product_item .item_image img {
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  transition: 0.6s cubic-bezier(0.25, 1, 0.5, 1);
}

@media (max-width: 768px) {
  .sports_product_item .item_image {
    display: flex;
    height: auto;
    border-radius: 4px;
    position: relative;
    align-items: center;
    justify-content: center;
    border: 2px solid transparent;
    transition: 0.6s cubic-bezier(0.25, 1, 0.5, 1);
  }
  .sports_product_item .item_content .item_title {
    font-size: 15px;
    padding: 15px 18px;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .page-link {
    padding: 0.25rem 0.5rem;
  }
  .products-list-main .sports_breadcrumb_nav_wrap,
  .productpage-main .sports_breadcrumb_nav_wrap {
    position: -webkit-sticky;
    position: sticky;
    top: 65px;
    z-index: 99;
    background-color: #fff;
  }
  .productpage-main .sports_breadcrumb_nav_wrap li {
    font-size: 16px;
  }
}

@media screen and (max-width: 991px) {
  .login-btns-div .custom_btn.btn_sm {
    font-size: 14px;
    padding: 10px 20px !important;
  }
}

.cart-table-item img {
  width: 100%;
  max-height: 141px;
  object-fit: contain;
}

.cart-table-item .quantity_input {
  height: 31px;
  padding: 0px 10px;
  border: 1px solid #ff3f3f;
}

.cart-table-item .quantity_input span {
  color: #ff3f3f;
  /* line-height: 1; */
  cursor: pointer;
  font-size: 16px;
  margin: 0px 10px;
  transition: 0.6s cubic-bezier(0.25, 1, 0.5, 1);
}

.cart-table-item .quantity_input input {
  width: 55px;
}

.cart-table-item .item_title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.span-red-text {
  color: #ff3f3f;
}

.about-us-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (min-width: 768px) {
  #offcanvasExample {
    display: none;
  }
}

.offcanvas.open {
  left: 0;
}

.offcanvas {
  position: fixed;
  top: 0;
  left: -100%;
  height: 100vh;
  width: 100vw;
  background: white;
  z-index: 9999;
  transition: all 0.3s ease-in-out;
  overflow-y: scroll;
}

.offcanvas .title {
  color: #ff3f3f;
}

.offcanvas-header {
  position: sticky;
  top: 0;
  left: 0;
}
.nice-select {
  width: 100%;
}
.shop-sidebar h5 {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 25px;
  padding-bottom: 15px;
  position: relative;
  text-transform: uppercase;
}
.mb-30 {
  margin-bottom: 30px;
}

.shop-sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.shop-sidebar ul a {
  color: black;
  text-decoration: none;
  font-size: 21px;
  font-weight: 500;
}
.range-slider .range-slider__range {
  position: absolute;
  z-index: 1;
  transform: translate(0, -50%);
  top: 50%;
  width: 100%;
  height: 100%;
  background: #ff3f3f !important;
}

.range-slider .range-slider__thumb {
  border: 3px solid #ff3f3f;
  background: white !important;
}

/* #searchmodal {
} */
#searchmodal .modal-dialog {
  /* max-width: 500px; */
  width: 100%;
  /* margin: 1.75rem auto; */
}

#searchmodal .modal-content {
  background-color: #000000d9;
}

@media (min-width: 576px) {
  #searchmodal .modal-dialog {
    max-width: 100% !important;
    margin: none;
  }
}

#searchmodal button.close {
  color: white !important;
  /* opacity: 1 !important; */
}

#searchmodal .modal-body input {
  width: calc(100% - 20px);
  border: none;
  height: 65px;
  padding: 0px;
  outline: none;
  display: block;
  color: #ffffff;
  font-size: 20px;
  background: transparent;
}

#searchmodal form button {
  top: 55%;
  right: 5px;
  line-height: 1;
  color: #ffffff;
  font-size: 20px;
  position: absolute;
  transform: translateY(-55%);
}

.profile-section {
  padding-top: 130px;
  padding-bottom: 100px;
}

/* .profile-container {
  padding: 50px 0;
} */

.profile-cover img {
  height: 200px;
  width: 100%;
  object-fit: cover;
}
.profile-cover-bottom {
  position: relative;
}
.profile-image {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  top: -50%;
}
.profile-image img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  border-radius: 50%;
}

.profile-details-container .nav-link.active {
  background-color: #ff3f3f;
  color: white;
}
.profile-details-container .nav-link {
  /* background-color: #ff3f3f; */
  color: black;
}

.outline-0 {
  outline: none !important;
}
.sidebar_mobile_menu .mobile_menu_list .dropdown-toggle:after {
  margin: 0px;
  border: none;
  float: right;
  position: static;
  font-weight: 900;
  content: "";
  margin-left: 6px;
  display: inline-block;
  vertical-align: middle;
  font-family: "Font Awesome 5 Pro";
}

.breadcrumb_section {
  position: relative;
  /* Add any other necessary styles */
}

.category-sidebar-img {
  width: 100%;
}

.category-sidebar-row {
  height: 400px;
  overflow: auto;
}

.category-sidebar-row::-webkit-scrollbar {
  display: none;
}

.products-list-main .pagination-main-box {
  position: sticky;
  bottom: 0;
  z-index: 9;

  padding-top: 10px;
}

.products-list-main .pagination-main-box ul {
  background: #ffffff;
  border-radius: 9px;
  padding: 5px;
  margin: 0;
}

/* */

.products-list-main-wrapper .scrolltotopbtndiv {
  bottom: 62px;
}

.offer-sticky {
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: #ff3f3f;
  padding: 2px 3px;
  border-radius: 5px;
  font-size: 11px;
  font-weight: 500;
  color: white;
  z-index: 1;
}

.wishlist-sticky {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}

.product-card .carousel-indicators li {
  height: 3px;
  width: 3px;
  border-radius: 50%;
}

.product-card .carousel-indicators li.active {
  height: 3px;
  width: 3px;
  background-color: #ff3f3f;
}

.discounted-price {
  color: #ff3f3f;
  font-weight: 500;
  margin-right: 5px;
}
.actual-price {
  color: lightgray;
}

.product-card-name {
  font-size: 17px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
  margin-bottom: 0;
  text-decoration: none;
  color: #000000;
}

.product-card-details:hover .product-card-name {
  color: #ff3f3f;
}

.addtobag-icon {
  border-radius: 50%;
  background-color: #ff3f3f;
  width: 30px;
  height: 30px;
  font-size: 15px;
  color: white;
}
.product-card-rating i {
  color: gold;
  font-size: 10px;
}

.product-card {
  margin-bottom: 20px;
  border-radius: 20px;
}

.product-card:hover {
  box-shadow: 0 0.5rem 1rem #ff3f3f42 !important;
}

.product-card .carousel-item {
  height: 275px;
}
.product-carosuel-img {
  width: 100%;
  height: 100%;
}

.product-carosuel-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 576px) {
  section.product-page-section {
    padding-top: 0;
  }
  .mob-product-slider {
    position: sticky;
    top: 123px;
    z-index: 0;
    background-color: #000000;
  }

  .product-card-name {
    font-size: 11px;
  }
  .product-list-col {
    padding: 0;
  }
  .product-card-col {
    padding: 5px;
  }
  .product-card .carousel-item {
    height: 150px;
  }
  .product-card .carousel-indicators {
    margin-bottom: 0;
  }
  .fashion_category_circle .item_offer {
    top: 0px;
    left: 0px;
    z-index: 2;
    width: 30px;
    height: 30px;
    line-height: 1;
    padding: 8px 0px;
    position: absolute;
    text-align: center;
    border-radius: 100%;
  }
  .fashion_category_circle .item_offer span {
    display: block;
    font-size: 8px;
    font-weight: 700;
  }
}
@media screen and (max-width: 767px) {
  .sports_absolute_tabnav a {
    font-size: 14px;
    padding: 6px 7px;
  }
}

.featured-products-mob-wrapper .slick-dots {
  display: flex !important;
}
@media screen and (max-width: 991px) {
  .fashion_category_circle .item_image {
    width: 100%;
    height: auto;
  }
}
@media screen and (max-width: 991px) {
  .fashion_category_circle .item_title {
    font-size: 18px;
  }
}

.mobile-product-apge-slider .slick-dots {
  display: flex !important;
}

.mob-product-slider-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.mob-product-slider-item img {
  width: 100%;
  height: 360px;
  object-fit: contain;
}

.product-page-details-wrapper {
  margin-top: -10px;
  background-color: white;
  border-radius: 20px 20px 0 0;
}

.bg-black {
  background-color: black;
}

.filters-bottom-fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  z-index: 999;
  background: white;
}

.filters-bottom-fixed button {
  height: 45px;
}

.sort-collapse {
  border-radius: 20px 20px 0 0;
  overflow: hidden;
  box-shadow: 0px -5px 13px 0px #00000066;
}
#sortbycollapse .list-group-item.active {
  background-color: #ff3f3f;
}
